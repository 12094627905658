<template>
  <div class="account-pages my-5 pt-5 text-left">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-soft-primary">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Seja Bem-Vindo</h5>
                    <p>Informe seus dados de acesso.</p>
                  </div>
                </div>
                <div class="col-5 align-self-end">
                  <img
                    src="@/assets/images/profile-img.png"
                    alt
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="card-body pt-3">
              <div class="p-2">
                <form class="form-horizontal">
                  <div class="form-group">
                    <label for="username">E-mail</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="E-mail..."
                      v-model="form.email"
                      :class="{ 'is-invalid': form && $v.form.email.$error }"
                    />
                  </div>

                  <div class="form-group">
                    <label for="userpassword">Senha</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password"
                      placeholder="Senha..."
                      :class="{ 'is-invalid': form && $v.form.password.$error }"
                    />
                  </div>

                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customControlInline"
                      v-model="form.check"
                    />
                    <label
                      class="custom-control-label"
                      for="customControlInline"
                      >Lembrar dados de acesso</label
                    >
                  </div>

                  <div class="mt-3">
                    <button :disabled="sendbtn" @click.prevent="submit()" class="btn btn-primary btn-block" type="submit">
                      Acessar
                    </button>
                  </div>
                  <div class="mt-4 text-center">
                    <router-link
                      tag="a"
                      to="/recover"
                      class="text-muted"
                    >
                      <i class="mdi mdi-lock mr-1"></i> Esqueceu sua senha?
                    </router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
            <p class="text-center">
              © {{ new Date().getFullYear() }} LP10 Desenvolvimento Web
            </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import {
  required,
  email,
} from "vuelidate/lib/validators";
export default {
   data () {
    return {
      sendbtn: false,
      form:{
        email: null,
        password: null,
        check: false
      }
    }
  },
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  mounted(){
    this.recuperarCook()
  },
  methods: {
    ...mapActions('auth', ['ActionDoLogin']),
    submit(){
      this.$v.$touch()
      if(!this.$v.$invalid){
        this.submitlogin()
      }
    },
    async submitlogin(){
      try {
        this.sendbtn = true
        await this.ActionDoLogin(this.form)
        this.setCook()
        this.sendbtn = false
        this.$swal({
          title: "Sucesso!",
          text: "Login realizado com sucesso!",
          icon: "success",
          timer: 2000,
          timerProgressBar: true,
        }).then(()=>{
            this.$router.push({ name: 'home' })
        });
      } catch (err) {
        this.sendbtn = false
        this.$swal({
          title: "Erro ao Realizar Login!",
          text: err.body.message,
          icon: "error",
          timer: 2000,
          timerProgressBar: true,
        });
      }
    },
    setCook(){
      if(this.form.check){
        $cookies.set('email',this.form.email);
        $cookies.set('password',this.form.password);
      }else{
        $cookies.remove('email')
        $cookies.remove('password')
      }
    },
    recuperarCook(){
        if($cookies.get('email') && $cookies.get('password') && $cookies.get('email') != "" && $cookies.get('password') != ""){
          this.form.email = $cookies.get('email')
          this.form.password = $cookies.get('password')
          this.form.check = true
        }else{
          this.form.check = false
        }
    }
  },
}
</script>